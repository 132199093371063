.whatsapp-float {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 40px;
  background-color: #25D366;
  color: white;
  border-radius: 40%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  
  font-size: 30px;
  /* line-height: 60px; */
  z-index: 100;
  box-shadow: 2px 2px 3px #999;
  text-decoration: none;
}